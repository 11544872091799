import React from "react";
import "./Produk2.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import Merantiimages from "./Meranti.js";
import Mawarimages from "./Mawar.js";
import Magnoliaimages from "./Magnolia.js";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowsUpDownLeftRight} from "@fortawesome/free-solid-svg-icons";
import {faHouse} from "@fortawesome/free-solid-svg-icons";
import {faBed} from "@fortawesome/free-solid-svg-icons";
import {faShower} from "@fortawesome/free-solid-svg-icons";
import {faWhatsapp} from "@fortawesome/free-brands-svg-icons";

const Produk1 = () => {
  const settings = {
    dots: true,
    arrows: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const Melatiwa = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6285338197085&text=Halo%20Tia,%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20produk%20Rumah%20Melati%20https://gcb-greenwoodscountrybogor.com/&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  };
  const Mawarwa = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6285338197085&text=Halo%20Tia,%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20produk%20Rumah%20Mawar%20https://gcb-greenwoodscountrybogor.com/&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  };
  const Magnoliawa = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6285338197085&text=Halo%20Tia,%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20produk%20Rumah%20Magnolia%20https://gcb-greenwoodscountrybogor.com/&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  };

  return (
    <div className="container">
      <div id="flamboyan" className="container-header1">
        {" "}
        Cluster Flamboyan
      </div>
      <div className="containercard1">
        <div className="card">
          <Slider {...settings}>
            {Merantiimages.map((image, index) => (
              <img className="imagecard" key={index} src={image} />
            ))}
          </Slider>
          <div className="container-judul-kartu">
            <div className="judulkartu">
              <div className="nama-rumah">Melati</div>
              <span>Subsidi DP</span>
            </div>
            <div className="cicilan">
              <div className="angka">2</div>
              <div className="ket">Juta/Bulan</div>
            </div>
          </div>
          <hr color="black" />
          <div className="gridspek">
            <FontAwesomeIcon color="#393939" icon={faArrowsUpDownLeftRight} />
            <span> : 66m²</span>
            <FontAwesomeIcon color="#393939" icon={faHouse} />
            <span> : 34m²</span>
            <FontAwesomeIcon color="#393939" icon={faBed} />
            <span>: 2</span>
            <FontAwesomeIcon color="#393939" icon={faShower} />
            <span>: 1</span>
          </div>
          <div className="containerwhatsapp">
            <button onClick={Melatiwa} className="whatsapp">
              <FontAwesomeIcon icon={faWhatsapp} /> Whatsapp
            </button>
          </div>
        </div>
        <div className="card">
          <Slider {...settings}>
            {Mawarimages.map((image, index) => (
              <img className="imagecard" key={index} src={image} />
            ))}
          </Slider>
          <div className="container-judul-kartu">
            <div className="judulkartu">
              <div className="nama-rumah">Mawar</div>
              <span>Subsidi DP</span>
            </div>
            <div className="cicilan">
              <div className="angka">3</div>
              <div className="ket">Juta/Bulan</div>
            </div>
          </div>
          <hr color="black" />
          <div className="gridspek">
            <FontAwesomeIcon color="#393939" icon={faArrowsUpDownLeftRight} />
            <span> : 88m²</span>
            <FontAwesomeIcon color="#393939" icon={faHouse} />
            <span> : 40m²</span>
            <FontAwesomeIcon color="#393939" icon={faBed} />
            <span>: 2</span>
            <FontAwesomeIcon color="#393939" icon={faShower} />
            <span>: 1</span>
          </div>
          <div className="containerwhatsapp">
            <button onClick={Mawarwa} className="whatsapp">
              <FontAwesomeIcon icon={faWhatsapp} /> Whatsapp
            </button>
          </div>
        </div>
        <div className="card">
          <Slider {...settings}>
            {Magnoliaimages.map((image, index) => (
              <img className="imagecard" key={index} src={image} />
            ))}
          </Slider>
          <div className="container-judul-kartu">
            <div className="judulkartu">
              <div className="nama-rumah">Magnolia</div>
              <span>Subsidi DP</span>
            </div>
            <div className="cicilan">
              <div className="angka">5</div>
              <div className="ket">Juta/Bulan</div>
            </div>
          </div>
          <hr color="black" />
          <div className="gridspek">
            <FontAwesomeIcon color="#393939" icon={faArrowsUpDownLeftRight} />
            <span> : 88m²</span>
            <FontAwesomeIcon color="#393939" icon={faHouse} />
            <span> : 81m²</span>
            <FontAwesomeIcon color="#393939" icon={faBed} />
            <span>: 3</span>
            <FontAwesomeIcon color="#393939" icon={faShower} />
            <span>: 3</span>
          </div>
          <div className="containerwhatsapp">
            <button onClick={Magnoliawa} className="whatsapp">
              <FontAwesomeIcon icon={faWhatsapp} /> Whatsapp
            </button>
          </div>
        </div>
      </div>
      <div></div>
    </div>
  );
};

export default Produk1;
