import React from "react";
import "./lokasi.scss";
import lokasiimages from "../../Media/Greenwood/Screenshot 2024-07-15 145242.png";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheckDouble} from "@fortawesome/free-solid-svg-icons";

const lokasi = () => {
  const handleConsultationClick = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6285338197085&text=Halo%20Tia,%20Saya%20bisa%20minta%20detail%20Katalog,%20Simulasi,%20dan%20Promo%20terbaru%20Greenwoods%20Country%20Bogor%20(Location)%20gcb-greenwoodscountrybogor.com//&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  };
  return (
    <div id="lokasi" className="container-lokasi">
      <div className="container-judul">
        <div className="judul-lokasi">
          <h1>Lokasi </h1>
          <h2>Greenwoods</h2>
          <h3>Country Bogor</h3>
          <div className="deskripsi">
            <h4>Lokasi yang ideal</h4>
            <h5>untuk tinggal dengan</h5>
            <h6>View Danau Terbaik</h6>
          </div>
        </div>
        <div className="button-penawaran">
          <button className="buttonpenawaran" onClick={handleConsultationClick}>
            Learn More
          </button>
        </div>
      </div>
      <div id="lokasi" className="lokasi">
        <img
          className="lokasi-kbp"
          src={lokasiimages}
          alt="hardsell-summarecon-serpong"
        />
      </div>
    </div>
  );
};

export default lokasi;
