import React from "react";
import "./home.scss";
import Header from "../Section/Header copy/navbar.jsx";
import Banner from "../Section/Banner/banner";
import About from "../Section/About/about.jsx";
import Promo from "../Section/Promo/promo";
import Fasilitas from "../Section/Fasilitas/fasilitas.jsx";
import Rumah from "../Section/Rumah/rumah.jsx";
import Surrounding from "../Section/Surroundingarea/surroundingarea.jsx";
import Lokasi from "../Section/Lokasi/lokasi.jsx";
import Footer from "../Section/Footer/footer.jsx";
import {faWhatsapp} from "@fortawesome/free-brands-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import tombol from "../Media/Greenwood/tombol.webp";

const home = () => {
  const fungsiganteng = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6285338197085&text=Halo%20Tia,%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20Rumah%20https://gcb-greenwoodscountrybogor.com/%20&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  };
  return (
    <div className="home">
      <Header />
      <Banner />
      <About />
      <Promo />
      <Fasilitas />
      <Rumah />
      <Surrounding />
      <Lokasi />
      <Footer />
      <img
        onClick={fungsiganteng}
        src={tombol}
        className="tombolwa"
        alt="Hubungi Sekarang!"
      />
    </div>
  );
};

export default home;
