import React from "react";
import "./about.scss";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faWhatsapp} from "@fortawesome/free-brands-svg-icons";
import logokbp from "../../Media/Greenwood/Logo Greenwood (2).png";

const aboutkbp = () => {
  const aboutwa = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6285338197085&text=Halo%20Tia,%20Saya%20bisa%20minta%20detail%20Katalog,%20Simulasi,%20dan%20Promo%20terbaru%20Kota%20Baru%20Parahyangan%20https://gcb-greenwoodscountrybogor.com//&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  };

  return (
    <div className="aboutkbp">
      <div id="about" className="container-aboutkbp">
        <div className="heading-aboutkbp">
          <div className="logokbp">
            <img className="gambarkbp" src={logokbp} alt="logo-kbp" />
          </div>
          <div className="judulkbp">Elegant Living with Lakefront Views</div>
          <div className="desk">
            Greenwoods Country Bogor adalah sebuah kawasan hunian yang mengusung
            konsep sustainability dan resort living. Kawasan ini terdiri dari
            hunian, danau, taman, tempat olah raga, dan berbagai aktivitas luar
            ruangan yang menawarkan kenyamanan dan kualitas hidup yang tinggi.
            Kawasan ini dibangun di atas lahan seluas 9,3 hektar dengan proyeksi
            perluasan mencapai 20 hektar. Lebih dari sebuah hunian, Greenwoods
            Country Bogor adalah kawasan hijau luas yang dirancang untuk
            memudahkan penghuninya dalam beraktivitas dan menikmati lingkungan
            alam yang seimbang dengan fasilitas modern.
          </div>
          <div className="button">
            <button className="wa" onClick={aboutwa}>
              <FontAwesomeIcon icon={faWhatsapp} />
              &nbsp; More Info
            </button>
          </div>
        </div>
        <div className="gambaraboutkbp"></div>
      </div>
    </div>
  );
};

export default aboutkbp;
